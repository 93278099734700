import React, { useState, useEffect, PropsWithChildren, useMemo } from "react";
import Client from "shopify-buy";
import { isBrowser } from "react-device-detect";

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN as string,
  domain: process.env.GATSBY_SHOP_URL as string,
  apiVersion: "2023-07",
});

type AddVariantToCart = (
  variantId: string,
  quantity: number,
  attributes?: { [key: string]: unknown }
) => Promise<unknown>;

type RemoveLineItem = (lineItemID: string) => Promise<void>;

type UpdateLineItem = (lineItemID: string, quantity: number) => Promise<void>;

type AddDiscountCode = (discountCode: string) => Promise<void>;

type StoreState = {
  client: Client;
  adding: boolean;
  checkout: Client.Checkout | undefined;
};

type StoreContext = {
  productIsAvaliable: (
    productId: string,
    variantId: string
  ) => Promise<boolean>;
  isCartOpen: boolean;
  showMemberModal: boolean;
  setShowMemberModal: (val: boolean) => void;
  isDesktop: boolean;
  closeCart: () => void;
  toggleCartOpen: () => void;
  store: StoreState;
  addVariantToCart: AddVariantToCart;
  removeLineItem: RemoveLineItem;
  updateLineItem: UpdateLineItem;
  addDiscountCode: AddDiscountCode;
  setHasMemberDiscountCode: (val: boolean) => void;
  getDiscount: () => void;
  hasMemberDiscountCode: boolean;
};

const storeContext = React.createContext<StoreContext>({} as StoreContext);

export const StoreContextProvider = (props: PropsWithChildren) => {
  let initialStoreState: StoreState = {
    client,
    adding: false,
    checkout: undefined,
  };
  const [isCartOpen, setCartIsOpen] = useState(false);
  const [store, updateStore] = useState<StoreState>(initialStoreState);
  const isDesktop = isBrowser;
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [hasMemberDiscountCode, setHasMemberDiscountCode] = useState(false);

  const checkMemberDiscount = (checkout: Client.Checkout) => {
    const existingDiscount = checkout?.discountApplications.find(
      discount =>
        discount.targetSelection == "ALL" &&
        discount.allocationMethod == "ACROSS" &&
        discount.value?.percentage == 15 &&
        discount?.code.slice(-4) == new Date().getFullYear().toString()
    );
    if (existingDiscount) setHasMemberDiscountCode(true);
  };

  useEffect(() => {
    if (typeof window == "undefined") return;

    const initializeCheckout = async () => {
      // Check for an existing cart.

      const existingCheckoutID = localStorage.getItem("shopify_checkout_id");

      const setCheckoutInState = (checkout: Client.Checkout) => {
        localStorage.setItem("shopify_checkout_id", checkout.id);

        updateStore(prevState => {
          return { ...prevState, checkout };
        });
      };

      if (existingCheckoutID) {
        try {
          const checkout = await store.client.checkout.fetch(
            existingCheckoutID
          );
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            checkMemberDiscount(checkout);
            return;
          }
        } catch (e) {
          localStorage.removeItem("shopify_checkout_id");
        }
      }

      const newCheckout = await store.client.checkout.create();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();

    return () => {};
  }, [store.client.checkout]);

  const productIsAvaliable = async (productId: string) => {
    console.log(productId);
    const res = await store?.client.product.fetch(productId);
    console.log(res);
    return res.variants.reduce(
      (acc, variant) => ({ ...acc, [variant.id]: variant.available }),
      {}
    );
  };

  const context = useMemo<StoreContext>(
    () => ({
      productIsAvaliable,
      showMemberModal,
      setShowMemberModal,
      setHasMemberDiscountCode,
      isCartOpen,
      isDesktop,
      closeCart: () => setCartIsOpen(false),
      toggleCartOpen: () => setCartIsOpen(status => !status),
      store,
      addVariantToCart: async (variantId, quantity, attributes) => {
        if (variantId === "" || !quantity) {
          console.error("Both a size and quantity are required.");
          return;
        }
        updateStore(prevState => {
          return { ...prevState, adding: true };
        });

        const { checkout, client } = store;

        const checkoutId = checkout?.id;
        if (!checkoutId) {
          return;
        }

        const customAttributes = attributes
          ? Object.entries(attributes).reduce(
              (ca, attr) => [...ca, { key: attr[0], value: attr[1] }],
              []
            )
          : undefined;

        const lineItemsToUpdate = [
          {
            variantId,
            quantity,
            ...(customAttributes && { customAttributes }),
          },
        ];

        const newCheckout = await client.checkout.addLineItems(
          checkoutId,
          lineItemsToUpdate
        );
        setCartIsOpen(true);
        updateStore(prevState => {
          return { ...prevState, checkout: newCheckout, adding: false };
        });
      },
      removeLineItem: async lineItemID => {
        if (!store.checkout) return;
        const res = await client.checkout.removeLineItems(store.checkout.id, [
          lineItemID,
        ]);

        updateStore(prevState => {
          return { ...prevState, checkout: res };
        });
      },
      updateLineItem: async (lineItemID, quantity) => {
        if (!store.checkout) return;

        const lineItemsToUpdate = [{ id: lineItemID, quantity }];

        const res = await client.checkout.updateLineItems(
          store.checkout.id,
          lineItemsToUpdate
        );

        updateStore(prevState => {
          return { ...prevState, checkout: res };
        });
      },
      addDiscountCode: async discountCode => {
        if (!store.checkout) return false;
        const newCheckout = await client.checkout.addDiscount(
          store.checkout?.id,
          discountCode
        );

        updateStore(prevState => {
          return { ...prevState, checkout: newCheckout };
        });
        checkMemberDiscount(newCheckout);
      },
      getDiscount: () => {
        return store?.checkout?.discountApplications?.length
          ? store.checkout.discountApplications[0]
          : undefined;
      },
      hasMemberDiscountCode,
    }),
    [store, showMemberModal, isCartOpen, hasMemberDiscountCode]
  );

  return (
    <storeContext.Provider value={context}>
      {props.children}
    </storeContext.Provider>
  );
};
export default storeContext;
